/** Icons are imported separatly to reduce build time */
import DocumentTextIcon from '@heroicons/react/24/outline/DocumentTextIcon'
import TableCellsIcon from '@heroicons/react/24/outline/TableCellsIcon'
import UserIcon from '@heroicons/react/24/outline/UserIcon'
import UsersIcon from '@heroicons/react/24/outline/UsersIcon'
import ClipboardDocumentListIcon from '@heroicons/react/24/outline/ClipboardDocumentListIcon'
import ClipboardIcon from '@heroicons/react/24/outline/ClipboardIcon'
import WindowIcon from '@heroicons/react/24/outline/WindowIcon'
import UserGroupIcon from '@heroicons/react/24/outline/UserGroupIcon'
import PresentationChartLineIcon from '@heroicons/react/24/outline/PresentationChartLineIcon'

const iconClasses = `h-6 w-6`
const submenuIconClasses = `h-5 w-5`

const refereeRoutes = [

  {
    path: '/jury/evaluations',
    icon: <ClipboardDocumentListIcon className={iconClasses} />,
    name: 'Оценка работ',
  },
  {
    path: '/jury/evaluations/protocols/common', // url
    icon: <ClipboardIcon className={iconClasses} />, // icon component
    name: 'Общий протокол', // name that appear in Sidebar
  },

]

const adminRoutes = [
  {
    path: '/admin/participants', // url
    icon: <UsersIcon className={iconClasses} />, // icon component
    name: 'Участники', // name that appear in Sidebar
  },
  {
    path: '/admin/juries', // url
    icon: <DocumentTextIcon className={iconClasses} />, // icon component
    name: 'Члены жюри', // name that appear in Sidebar
  },
  {
    path: '', //no url needed as this has submenu
    icon: <ClipboardDocumentListIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Протоколы', // name that appear in Sidebar
    submenu: [
      {
        path: 'protocols/common',
        icon: <TableCellsIcon className={submenuIconClasses} />,
        name: 'Общий протокол',
      },
      {
        path: 'protocols/juries', //url
        icon: <PresentationChartLineIcon className={submenuIconClasses} />, // icon component
        name: 'Членов жюри', // name that appear in Sidebar
      },
      {
        path: 'protocols/participants',
        icon: <UserGroupIcon className={submenuIconClasses} />,
        name: 'Участников',
      },
    ]
  },
  // {
  //   path: 'activity-log', // url
  //   icon: <WindowIcon className={iconClasses} />, // icon component
  //   name: 'Журнал активности', // name that appear in Sidebar
  // },
  {
    path: 'system-users', // url
    icon: <UserIcon className={iconClasses} />, // icon component
    name: 'Орг. Пользователи', // name that appear in Sidebar
  },

]

const watcherRoutes = [
  {
    path: '/watcher/participants', // url
    icon: <UsersIcon className={iconClasses} />, // icon component
    name: 'Участники', // name that appear in Sidebar
  },
  {
    path: '/watcher/juries', // url
    icon: <DocumentTextIcon className={iconClasses} />, // icon component
    name: 'Члены жюри', // name that appear in Sidebar
  },
  {
    path: '', //no url needed as this has submenu
    icon: <ClipboardDocumentListIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Протоколы', // name that appear in Sidebar
    submenu: [
      {
        path: 'protocols/common',
        icon: <TableCellsIcon className={submenuIconClasses} />,
        name: 'Общий протокол',
      },
      {
        path: 'protocols/juries', //url
        icon: <PresentationChartLineIcon className={submenuIconClasses} />, // icon component
        name: 'Членов жюри', // name that appear in Sidebar
      },
      {
        path: 'protocols/participants',
        icon: <UserGroupIcon className={submenuIconClasses} />,
        name: 'Участников',
      },
    ]
  },
]

export { refereeRoutes, adminRoutes, watcherRoutes }


