import axios from "axios";
import AuthRepository from "./repositories/AuthRepository";
import { router } from "../routes";
import { NotificationManager } from "react-notifications";

let refreshing = false;

const repeatInterval = () =>
    new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!refreshing) {
                resolve();
                clearInterval(interval);
            }
        }, 100);
    });

class Service {
    static instance;

    constructor() {
        this._client = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        });

        this.resolvedInterceptor = this.resolvedInterceptor.bind(this);
        this.rejectedInterceptor = this.rejectedInterceptor.bind(this);

        this._client.interceptors.response.use(
            this.resolvedInterceptor,
            this.rejectedInterceptor
        );

        this._client.interceptors.request.use((config) => {
            const token = localStorage.getItem("access_token");
            // TODO исправить логику с проверкой на null
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
        Service.instance = this;
    }

    setBearer = token => {
        this._client.defaults.headers.common.Authorization = `Bearer ${token}`;
    };

    removeBearer = () => {
        delete this._client.defaults.headers.common.Authorization;
    };

    clearLocalStorage = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('role');
        // localStorage.removeItem('refresh_token');
        // TODO удалить данные о пользователе
    }

    setLocalStorage = (data) => {
        localStorage.setItem('access_token', data.token);
        // localStorage.setItem('refresh_token', data.refresh_token);
    }

    resolvedInterceptor(response) {
        return Promise.resolve(response);
    }

    async rejectedInterceptor(error) {
        const { status, config, data } = error.response;
        switch (status) {
            case 401: {
                const originalRequest = config;
                const isRefreshTokenRequest = originalRequest.url === "/auth/refresh";
                const isLoginRequest = originalRequest.url === "/auth/login";
                if (!isRefreshTokenRequest) {
                    if (!isLoginRequest) {
                        if (!refreshing) {
                            refreshing = true;
                            return this.refresh(originalRequest);
                        } else {
                            await repeatInterval();
                            return await this.repeatRequest(originalRequest);

                        }
                    }
                } else {
                    this.clearLocalStorage();
                    this.removeBearer();
                    router.navigate("/login");
                }
                break;
            }
            case 500:
            case 403:
            case 404:
            case 429:
            case 413:
                router.navigate(`/error-page?message=${data.error.message}&code=${status}`)
                break;
            default:
                break;
        }
        return Promise.reject(error);
    }

    static getInstance() {
        if (Service.instance) {
            return Service.instance;
        }
        return new Service();
    }

    refresh(originalRequest) {
        return new Promise((resolve, reject) => {

            AuthRepository.refresh()
                .then((response) => {
                    const { access_token } = response.data.data;
                    this.setLocalStorage({ token: access_token });
                    refreshing = false;
                    this.repeatRequest(originalRequest)
                        .then((res) => resolve(res))
                        .catch((err) => reject(err));
                })
                .catch(async () => {
                    this.clearLocalStorage();
                    this.removeBearer();
                    refreshing = false;
                    router.navigate("/login");
                });
        });
    }

    repeatRequest = async (originalRequest) => {
        const token = localStorage.getItem("access_token");
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return new Promise((resolve, reject) => {
            this._client(originalRequest)
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    }
}

export default Service.getInstance()._client;


// import axios from 'axios';
//
// const API_URL = `https://gaz-dril-conf-back-dev.truemachine.space/`
//
// const instance = axios.create({
//     baseURL: API_URL,
// });
//
// instance.interceptors.request.use(config => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
//     return config
// })
//
// export default instance;

