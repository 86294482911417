import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { CONFIRMATION_MODAL_CLOSE_TYPES, MODAL_CLOSE_TYPES } from '../../../utils/globalConstantUtil'
import { setDisabled } from '../../../app/slices/estimateButtonSlice'
import { showNotification } from '../../../app/slices/headerSlice'
import { putEvaluateParticipant, isLoadingJury } from '../../../app/slices/jurySlice'
import { router } from '../../../routes'
import { clearError, createJury, createParticipant, createUser, getParticipants, deleteEvaluations, deleteJury, deleteParticipant, deleteUser, getEvaluations, getJuryList, getOneJuryProtocol, getOneParticipantProtocol, getUserList, setError, showParticipant, updateEvaluations, updateJury, updateParticipant, updateUser } from '../../../app/slices/adminSlice'
import { useEffect } from 'react'
import AdminRepository from '../../../api/repositories/AdminRepository'

function ConfirmationModalBody({ extraObject, closeModal }) {
    const dispatch = useDispatch()
    const { error, isLoading } = useSelector(state => state.admin)
    const { isLoadingJury } = useSelector(state => state.participants)
    const { role } = useSelector(state => state.user)
    const { message, type, _id, index, isDesabled, data, tabValue } = extraObject

    const chooseLoading = (role) => {
        if (role === 'jury') {
            return isLoadingJury
        } else {
            return isLoading
        }
    }
    const proceedWithYes = async () => {
        switch (type) {

            case CONFIRMATION_MODAL_CLOSE_TYPES.DELETE_PARTICIPANT:
                await dispatch(deleteParticipant(extraObject.data.id))
                await dispatch(getParticipants())
                dispatch(showNotification({ message: "Участник удален", status: 1 }))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.DELETE_PARTICIPANT_DETAIL:
                dispatch(deleteParticipant(extraObject.data.id))
                dispatch(showNotification({ message: "Участник удален", status: 1 }))
                router.navigate('/admin/participants')
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.ESTIMATE:
                await dispatch(putEvaluateParticipant({
                    id: extraObject.data.id,
                    data: extraObject.data.evaluation
                }))
                await dispatch(showNotification({ message: "Оценки поставлены", status: 1 }))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.DELETE_JURY:
                dispatch(deleteJury(extraObject.data.id))
                dispatch(showNotification({ message: "Член жюри удален", status: 1 }))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.DELETE_JURY_DETAIL:
                dispatch(deleteJury(extraObject.data.id))
                dispatch(showNotification({ message: "Член жюри удален", status: 1 }))
                router.navigate(-1)
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.RESET_ESTIMATE:
                dispatch(deleteEvaluations(extraObject.data))
                dispatch(showNotification({ message: "Оценки сброшены", status: 1 }));
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.RESET_ESTIMATE_JURY_DETAIL:
                dispatch(deleteEvaluations(extraObject.data))
                router.navigate(`admin/protocols/juries/${extraObject.data.id}`)
                dispatch(showNotification({ message: "Оценки сброшены", status: 1 }));
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.RESET_ESTIMATE_PARTICIPANT_DETAIL:
                dispatch(deleteEvaluations(extraObject.data))
                dispatch(getOneParticipantProtocol({ id: extraObject.data.participant_id, section: tabValue }))
                router.navigate(`admin/protocols/participants/${extraObject.data.participant_id}`)
                dispatch(showNotification({ message: "Оценки сброшены", status: 1 }));
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.ESTIMATE_EDIT_JURY:
                dispatch(updateEvaluations({
                    id: extraObject.data.id,
                    participant_id: extraObject.data.participant_id,
                    data: extraObject.data.evaluation
                }))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Оценки изменены", status: 1 }))
                        dispatch(getEvaluations({
                            id: extraObject.data.id,
                            participant_id: extraObject.data.participant_id,
                        }))
                        router.navigate(`admin/protocols/juries/${extraObject.data.id}`)
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.ESTIMATE_EDIT_PARTICIPANT:
                dispatch(updateEvaluations({
                    id: extraObject.data.id,
                    participant_id: extraObject.data.participant_id,
                    data: extraObject.data.evaluation
                }))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Оценки изменены", status: 1 }))
                        dispatch(getEvaluations({
                            id: extraObject.data.id,
                            participant_id: extraObject.data.participant_id,
                        }))
                        router.navigate(`admin/protocols/participants/${extraObject.data.participant_id}`)
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.CREATE_PARTICIPANT:
                dispatch(createParticipant(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Участник создан", status: 1 }))
                        router.navigate(-1)
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.UPDATE_PARTICIPANT:
                dispatch(updateParticipant(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Данные сохранены", status: 1 }))
                        router.navigate('/admin/participants')
                        dispatch(getParticipants())
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.CREATE_JURY:
                dispatch(createJury(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Член жюри создан", status: 1 }))
                        router.navigate(-1)
                        // dispatch(getJuryList())
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.UPDATE_JURY:
                dispatch(updateJury(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Данные сохранены", status: 1 }))
                        router.navigate('/admin/juries')
                        dispatch(getJuryList())
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.CREATE_USER:
                dispatch(createUser(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Пользователь создан", status: 1 }))
                        router.navigate('/admin/system-users')
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.UPDATE_USER:
                dispatch(updateUser(extraObject.data))
                    .unwrap()
                    .then(() => {
                        dispatch(showNotification({ message: "Данные сохранены", status: 1 }))
                        router.navigate('/admin/system-users')
                        dispatch(getUserList())
                    })
                    .catch(e => (dispatch(showNotification({ message: e, status: 0 }))))
                break;

            case CONFIRMATION_MODAL_CLOSE_TYPES.DELETE_USER:
                dispatch(deleteUser(extraObject.data.id))
                router.navigate('/admin/system-users')
                dispatch(showNotification({ message: "Пользователь удален", status: 1 }))
                break;

            default:
                break;


        }
        closeModal()
    }
    return (
        <>
            <p className=' text-xl mt-8 text-center'>
                {message}
            </p>

            <div className="modal-action mt-12">
                <button className={`btn btn-secondary text-primary w-36 ${chooseLoading(role) ? " loading" : ""}`} onClick={() => proceedWithYes()}>ДА</button>
                <button className="btn btn-nuetral text-primary w-36" onClick={() => closeModal()}>НЕТ</button>
            </div>
        </>
    )
}

export default ConfirmationModalBody