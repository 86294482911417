import { lazy } from "react";
import ErrorPage from "../../pages/ErrorPage";

const Layout = lazy(() => import("../../containers/Layout"));

const WatcherParticipantsList = lazy(() => import('../../pages/watcher/Protocols/ParticipantsList'))
const WatcherCommonProtocol = lazy(() => import('../../pages/watcher/Protocols/CommonProtocol'))
const WatcherParticipantsProtocol = lazy(() => import('../../pages/watcher/Protocols/ParticipantsProtocol'))
const WatcherJuriesProtocol = lazy(() => import('../../pages/watcher/Protocols/JuriesProtocol'))
const WatcherJuryList = lazy(() => import('../../pages/watcher/Protocols/JuryList'))
const WatcherParticipants = lazy(() => import('../../pages/watcher/Participsnts/Participants'))
const WatcherParticipantsDetail = lazy(() => import('../../pages/watcher/Participsnts/ViewParticipant'))
const WatcherJuries = lazy(() => import('../../pages/watcher/Juries/JuriesList'))
const WatcherJuriesDetail = lazy(() => import('../../pages/watcher/Juries/ViewJury'))
// const WatcherEvaluationViewing = lazy(() => import('../../pages/watcher/Protocols/ProtocolJuryViewing'))
const WatcherProtocolParticipantsViewing = lazy(() => import('../../pages/watcher/Protocols/ProtocolParticipantsViewing'))
const WatcherProtocolJuryViewing = lazy(() => import('../../pages/watcher/Protocols/ProtocolJuryViewing'))


export const watcherRouter = [
    {
        path: 'watcher',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'protocols/common',
                element: <WatcherCommonProtocol />
            },
            {
                path: 'participants',
                element: <WatcherParticipants />
            },
            {
                path: 'participants/:id',
                element: <WatcherParticipantsDetail />
            },
            {
                path: 'juries',
                element: <WatcherJuries />
            },
            {
                path: 'juries/:id',
                element: <WatcherJuriesDetail />
            },
            {
                path: 'protocols/juries',
                element: <WatcherJuryList />
            },
            {
                path: 'protocols/juries/:id',
                element: <WatcherJuriesProtocol />
            },
            {
                path: 'protocols/participants',
                element: <WatcherParticipantsList />,
            },
            {
                path: 'protocols/participants/:participant_id',
                element: <WatcherParticipantsProtocol />,
            },
            {
                path: 'protocols/participants/:participant_id/jury/:id',
                element: <WatcherProtocolParticipantsViewing />
            },
            {
                path: 'protocols/juries/:id/participant/:participant_id',
                element: <WatcherProtocolJuryViewing />
            },
            // когда появится страница Список жюри
            // {
            //   path: 'protocols/juries:id',
            //   element: WatcherJuriesProtocol,
            // },
            // Когда появится страница Список участников

        ]
    }
]
