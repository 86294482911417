export const criteria = {
    c1: 'Актуальность и инновационность',
    c2: 'Личный вклад и степень проработанности',
    c3: 'Экономическая целесообразность',
    c4: 'Перспективность и практическая применимость',
    c5: 'Наглядность презентации и качество доклада',
}

export const sections = {
    s1: 'Техника и технология строительства скважин',
    s2: 'Экономика, менеджмент и управление персоналом',
    s3: 'Информационные и цифровые технологии',
    s4: 'Обеспечение процесса строительства скважин',
}