import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon'
import UserCircleIcon from '@heroicons/react/24/solid/UserCircleIcon'
import { RECENT_TRANSACTIONS } from '../utils/dummyData'
import { headerHandler } from '../utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../app/slices/authSlice'

function Header({ role }) {

    const dispatch = useDispatch()
    const onLogout = () => {
        dispatch(logout())
    }
    const { user } = useSelector(state => state.user)
    const userName = `${user.first_name} ${user.last_name}`
    return (
        <>
            <div className="navbar bg-base-100 shadow-sm" style={{ zIndex: 12 }}>

                {/* Кнопка разворачивания меню, при уменьшении размера страницы */}
                <div className="m-5">
                    <label htmlFor="left-sidebar-drawer" className="btn btn-ghost btn-nuetral drawer-button lg:hidden">
                        <Bars3Icon className="h-5 inline-block w-5" />
                    </label>
                </div>
                <div className='navbar flex justify-between mb-1'>

                    <div className='min-w-fit flex items-center'>
                        <img className='w-64 mr-24' src='/textLogo.png' alt='logo' />
                        <img className='w-32' src='/rusgazburLogo.png' alt='logo' />
                    </div>

                    <div>
                        <label className='text-base font-medium mr-5 ml-5'>{`${userName} | ${headerHandler(role)}`}</label>
                        <button
                            className='text-base font-medium mr-5'
                            style={{ color: "#0171c4" }}
                            onClick={() => onLogout()}
                        >
                            Выход
                        </button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Header